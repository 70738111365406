import {version} from "./version";
import {GrouppayAdmins} from "./grouppay-admins";


const innkjop = {
    version,
    GrouppayAdmins,
};

export default innkjop;
