export function GrouppayAdmins(location, url){
    dk.DataTable.create_on(location, {
        table_data: dk.data.Data.create({
            pagesize: 5,
            datasource: url,
        }),
        columns: {
            first_name: {label: 'Fornavn'},
            last_name: {label: 'Etternavn'},
            email: {label: 'E-post'},
            remove_admin: {
                label: 'Fjern',
                format: function (val, rec) {
                    return `<a data-remuserid="${rec.userid}" class="removeadmin"><dk-icon value="trash-o:fw"></dk-icon></a>`;
                }
            }
        },
    });
}
